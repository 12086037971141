import React from 'react'

function Contact({contact}) {
  return (
    <div>
    
    </div>
  )
}

export default Contact
